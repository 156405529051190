/*
 Import all product specific js
 */
/* eslint-disable no-undef */
/* eslint-disable one-var */

import $ from 'jquery';

$(document).ready(() => {
    $('.navUser a.user-account-drop').each(function () {
        $(this).click(() => {
            $('.user-account-drop-menu').toggleClass('is-open');
        });
    });
    $('#back-to-top').click(() => {
        $('body,html').animate({
            scrollTop: 0,
        }, 800);
    });
    $('.a-icon').click(() => {
        $('.dropdown--quickSearch .form-input').keypress();
    });
});
