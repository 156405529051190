import $ from 'jquery';
import utils from '@bigcommerce/stencil-utils';

export default function () {
    $(document).ready(() => {
        $('article.card').each(function () {
            const productId = $(this).attr('data-product-id');
            if (productId !== undefined) {
                utils.api.product.getById(productId, { template: 'demo/free-shipping' }, (err, response) => {
                    $('.free-shipping').html(response);
                });
            }
        });
    });
}
